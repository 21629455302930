import React from "react";
import { Heading } from "@/shared/components/ui";

import { CoverageModalTerms } from "@/shared/components/CoverageEditor";

interface TermsDialogProps {
    termsInModal: CoverageModalTerms[];
}

export const ReimbursementTermsModal: React.FC<TermsDialogProps> = props => {
    const { termsInModal } = props;

    return (
        <div className="text-md flex w-full flex-col gap-2 text-content-primary">
            <Heading level="h4" className="max-w-[80%] text-xl font-semibold">
                What do these terms mean?
            </Heading>
            {termsInModal.includes("annualLimit") && (
                <>
                    <Heading level="h4" className="text-lg font-semibold">
                        Annual Limit
                    </Heading>
                    <p className="text-content-secondary">
                        The total amount you can be reimbursed over one 12-month policy period. This limit resets whenever a new policy period begins.
                    </p>
                </>
            )}
            {termsInModal.includes("reimbursement") && (
                <>
                    <Heading level="h4" className="text-lg font-semibold">
                        Reimbursement Rate
                    </Heading>
                    <p className="text-content-secondary">
                        After the deductible is met, we will reimburse a percentage of covered expenses according to the reimbursement percentage you select.
                    </p>
                </>
            )}
            {termsInModal.includes("annualDeductible") && (
                <>
                    <Heading level="h4" className="text-lg font-semibold">
                        Annual Deductible
                    </Heading>
                    <p className="text-content-secondary">
                        The amount you must satisfy for covered veterinary expenses before you can start being reimbursed. You’ll only need to satisfy it once per 12-month policy
                        period.
                    </p>
                </>
            )}
        </div>
    );
};
