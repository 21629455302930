// components
import IconGrid from "@/shared/components/IconGrid";
import { Heading } from "@/shared/components/ui/Heading";
import { Button } from "@/shared/components/ui/Button";
import { IconButton } from "@/shared/components/ui/IconButton";
import { Badge } from "@/shared/components/ui/Badge";

// utils
import { PolicyUtils } from "@/shared/utils/PolicyUtils";
import { PreventiveCoverageType } from "@/shared/utils/CoverageUtils";

// media
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import DeleteIcon from "@/shared/media/icons/CircleMinusProRegular.svg";

// types
import { Card, CardHeader, CardContent, CardFooter } from "../ui/Card";
import { Pet } from "@/shared/types/Quote.interface";
import { IconGridItem } from "@/shared/types/IconGrid.interface";

interface PreventiveCardProps {
    pet: Pet;
    price?: number;
    label: string;
    image: JSX.Element;
    benefits: IconGridItem[];
    currentWellnessLevel: string;
    wellnessLevel: PreventiveCoverageType;
    onChange: (coverageLevel: PreventiveCoverageType) => void;
    sellingPoints?: string[];
    totalBenefitsValue?: number;
    showRecommendedAge?: boolean;
}
function PreventiveCard(props: PreventiveCardProps) {
    const { pet, price, label, benefits, image, wellnessLevel, sellingPoints, showRecommendedAge, totalBenefitsValue, currentWellnessLevel, onChange } = props;
    const isActive = wellnessLevel === currentWellnessLevel;

    return (
        <Card className="h-full rounded-3xl bg-background-primary shadow-md">
            <CardHeader className="flex h-36 flex-row items-center border-b border-stroke-secondary px-3 sm:px-4 lg:h-28">
                <div className="flex flex-col text-content-primary">
                    <div className="flex flex-col lg:flex-row lg:items-center lg:gap-3">
                        <Heading level="h3" className="order-1 lg:order-none lg:-mt-2">
                            {label}
                        </Heading>
                        {showRecommendedAge && Number.isInteger(pet.age) && pet.species && (
                            <Badge variant="secondary" className="mb-3 mt-1 self-start">
                                Recommended for {PolicyUtils.recommendedPreventiveText(pet.species, pet.age as number)}
                            </Badge>
                        )}
                    </div>
                    {totalBenefitsValue && <div className="text-content-primary">Up to ${totalBenefitsValue}/yr in benefits</div>}
                    {sellingPoints?.length && (
                        <div className="mt-1 flex flex-col lg:-mt-1 lg:flex-row lg:gap-2">
                            {sellingPoints.map((point, index) => (
                                <div key={index}>
                                    <FontAwesomeIcon icon={faCircleCheck} className="mr-1 text-content-brand-b" />
                                    {point}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="ml-auto">{image}</div>
            </CardHeader>
            <CardContent className="flex flex-col items-center gap-2 px-3 py-4 text-content-primary sm:p-4">
                <IconGrid gridItems={benefits} wrapperClass="gap-1 grid-cols-1 xl:grid-cols-2 xl:gap-x-4" isPreventive />
            </CardContent>
            <CardFooter className="mt-auto flex items-center justify-between gap-2 border-t border-stroke-secondary px-3 py-5 sm:px-4">
                <span className="flex flex-row justify-between font-bold text-content-brand-b">${price}/mo</span>
                <div className="flex items-center gap-2">
                    <Button variant={`${isActive ? "secondary" : "outline-secondary"}`} onClick={() => !isActive && onChange(wellnessLevel)}>
                        {isActive ? `${label} Added` : `Add ${label}`}
                    </Button>
                    {isActive && (
                        <IconButton variant="destructive" className="p-0" title={`Remove ${label}`} onClick={() => onChange("None")}>
                            <DeleteIcon className="size-6" />
                        </IconButton>
                    )}
                </div>
            </CardFooter>
        </Card>
    );
}

export default PreventiveCard;
