import React from "react";
import { X } from "lucide-react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose } from "@/shared/components/ui/Dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { cn } from "@/shared/utils";

export interface ModalWrapperStyles {
    content?: string;
    header?: string;
    title?: string;
    description?: string;
    footer?: string;
    closeButton?: string;
}

interface ModalWrapperProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string | React.ReactNode;
    description?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    styles?: ModalWrapperStyles;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({ isOpen, onClose, title, description, children, footer, styles = {} }) => {
    const accessibleTitle = title || "Dialog Content";

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className={cn(styles.content)}>
                <DialogClose asChild>
                    <button
                        className={cn(
                            "ring-offset-background focus:ring-ring data-[state=open]:bg-accent data-[state=open]:text-muted-foreground absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none",
                            styles.closeButton
                        )}
                    >
                        <X className="size-4" />
                        <span className="sr-only">Close</span>
                    </button>
                </DialogClose>
                <DialogHeader className={cn(styles.header)}>
                    {title ? (
                        <DialogTitle className={cn(styles.title)}>{title}</DialogTitle>
                    ) : (
                        <VisuallyHidden asChild>
                            <DialogTitle>{accessibleTitle}</DialogTitle>
                        </VisuallyHidden>
                    )}
                    {description && <DialogDescription className={cn(styles.description)}>{description}</DialogDescription>}
                </DialogHeader>
                {children}
                {footer && <DialogFooter className={cn(styles.footer, "justify-center")}>{footer}</DialogFooter>}
            </DialogContent>
        </Dialog>
    );
};

ModalWrapper.displayName = "ModalWrapper";
