import React, { useRef, useState } from "react";
import { cn } from "../utils";
import { IconGridProps } from "../types/IconGrid.interface";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger, PopoverClose, PopoverArrow } from "@radix-ui/react-popover";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { useStatsig } from "@/shared/hooks/useStatsig";
import { PublicConfig } from "@/shared/PublicConfig";

export default function IconGrid({
    gridItems,
    wrapperClass,
    isPreventive = false,
    initialViewableCount = 4,
    isLargeScreen,
    itemClass
}: IconGridProps & { isPreventive?: boolean; initialViewableCount?: number; isLargeScreen?: boolean; itemClass?: string }) {
    const gridRef = useRef<HTMLDivElement>(null);
    const isBrowser = typeof window !== `undefined`;
    const initialScrollOffset = isBrowser ? window.scrollY : 0;
    const [showMore, setShowMore] = useState(false);
    const [scrollOffset, setScrollOffset] = useState(initialScrollOffset);
    const displayedItems = showMore || isPreventive ? gridItems : gridItems.slice(0, initialViewableCount);

    const handleShowMore = () => {
        if (isBrowser) {
            setScrollOffset(window.scrollY);
        }
        setShowMore(true);
    };

    const handleShowLess = () => {
        setShowMore(false);
        if (isBrowser) {
            window.scrollTo({ top: scrollOffset, left: 0, behavior: "auto" });
        }
    };

    // Statsig:start
    const { showCoverageIconContent } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "showCoverageIconContent", defaultValue: false }] as const
    });
    // Statsig:end

    const conditionalTriggerClasses = {
        "flex-row": isPreventive, // Preventive coverage Cards
        "flex-col": !isPreventive, // Accident + Illness and Accident Only icon grid
        "hover:bg-background-secondary py-2": showCoverageIconContent && !isPreventive,
        "pointer-events-none": !showCoverageIconContent || isPreventive
    };

    return (
        <>
            {!!gridItems.length && (
                <div ref={gridRef} className={cn(isPreventive ? "grid w-full gap-4" : "grid w-full gap-3 md:flex md:flex-wrap md:justify-center", wrapperClass)}>
                    {displayedItems.map(({ icon, text, content, amount }, index) => (
                        <React.Fragment key={`grid-item-${index + 1}`}>
                            <div className={`flex w-full items-center text-xs ${isPreventive ? "gap-2" : `flex-col text-center md:w-[160px] xl:w-[150px] ${itemClass}`}`}>
                                <div className="flex w-full justify-between">
                                    <Popover>
                                        <PopoverTrigger
                                            className={cn(`flex w-full items-center gap-1 rounded text-center`, conditionalTriggerClasses)}
                                            tabIndex={isPreventive || !showCoverageIconContent ? -1 : undefined}
                                        >
                                            <div className="justify-center">{icon}</div>
                                            {!!text && (
                                                <span className={`${isPreventive ? "text-left" : "line-clamp-2 whitespace-pre text-center md:mx-3 lg:mx-0"} font-medium leading-5`}>
                                                    {text}
                                                </span>
                                            )}
                                        </PopoverTrigger>
                                        {content && (
                                            <PopoverContent
                                                onOpenAutoFocus={event => event.preventDefault()}
                                                className="PopoverContent z-10 flex max-w-[200px] flex-col gap-2 rounded-md border border-stroke-primary bg-background-primary p-3 text-left shadow-md sm:max-w-[320px]"
                                                align="center"
                                            >
                                                <PopoverClose className="absolute right-2 top-2 flex h-auto focus:absolute">
                                                    <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                                                </PopoverClose>
                                                <div className="mr-5 font-bold">{content?.definition}</div>
                                                <div className="font-medium">{content?.value}</div>
                                                <div className="rounded bg-background-secondary p-2">{parse(content?.fact)}</div>
                                                <PopoverArrow className="popover-arrow" width="12" height="9" />
                                            </PopoverContent>
                                        )}
                                    </Popover>
                                </div>
                                {!!amount && <span className="ml-auto sm:ml-2">${amount}</span>}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}

            {!!initialViewableCount && gridItems.length > initialViewableCount && !isLargeScreen && !isPreventive && (
                <div className="col-span-2 flex items-center justify-center">
                    <button type="button" className="mt-4 flex items-center justify-center" onClick={!showMore ? handleShowMore : handleShowLess}>
                        <div className="text-md text-type-primary mr-2">{!showMore ? `Show More` : `Show Less`}</div>
                        {!showMore ? <ChevronDown /> : <ChevronUp />}
                    </button>
                </div>
            )}
        </>
    );
}
