import React from "react";
import LoaderWithText from "./LoaderWithText";
import { Dialog, DialogContent, DialogTitle } from "./ui";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

export function LoaderModal() {
    return (
        <Dialog open={true}>
            <DialogContent className="items-center justify-center">
                <VisuallyHidden asChild>
                    <DialogTitle>Wrapping up...</DialogTitle>
                </VisuallyHidden>
                <LoaderWithText text="Wrapping up..." />
            </DialogContent>
        </Dialog>
    );
}
